import './ModalHistory.css';

const ModalHistory = ({ closeDownload }) => (
  <div class='modal__password'>

    <div class='modal__request-dialog'>
      <div class='modal__request-header'>
        <h3 class='modal__title-sent'>Отсутствуют файлы для скачивания</h3>
        <button
          type='reset'
          form='form'
          class='close'
          onClick={closeDownload}
        >
          &times;
        </button>
      </div>
    </div>
  </div>
);

export default ModalHistory;