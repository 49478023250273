import "./Park.css";

import Parkitem from "../Parkitem/Parkitem";
import Title from "../Title/Title";
import img1 from "../../img/park-img1.png";
import img2 from "../../img/park-img2.png";
import img3 from "../../img/park-img3.png";
import img4 from "../../img/park-img4.png";
import img5 from "../../img/park-img5.png";
import img6 from "../../img/park-img6.png";

const parkitem = [
  {
    image: img1,
    title: "Премиум (VIP)",
    text:
      "Представительские автомобили премиум класса с водителем и без водителя для организации деловых поездок, бизнес встреч, элитные кортежи на свадьбу, трансфер в аэропорт и вокзалы для частных клиентов и юридических лиц.",
  },
  {
    image: img2,
    title: "Минивэны",
    text:
      "Автопарк укомплектован вместительными, современными 6, 7, 8 и 9-местными минивэнами и мультиванами для поездок по городу, длительных путешествий по стране и с выездом за границу. Транспортным средством можно управлять, имея водительское удостоверение категории «B».",
  },
  {
    image: img3,
    title: "Внедорожники, джипы",
    text:
      "Для любителей больших и мощных автомобилей с повышенной проходимостью предлагаем арендовать внедорожники, кроссоверы и джипы на сутки или длительный срок для индивидуальных поездок, деловых встреч, торжественных мероприятий и выезда на природу.",
  },
  {
    image: img4,
    title: "Бизнес класс",
    text:
      "К услугам клиентов широкий модельный ряд автомобилей среднего класса. Модели данного сегмента характеризуют повышенный комфорт и техническая оснащенность, устойчивость на трассе, маневренность, динамичность, просторный салон и вместительный багажник.",
  },
  {
    image: img5,
    title: "Эконом класс",
    text:
      "К вашим услугам прокат автомобиля эконом-класса без водителя и с водителем для поездок по столице и за ее пределами. Снять автомашину можно от часа, посуточно и на длительный срок. Долгосрочный прокат бюджетных автомобилей будет стоить значительно дешевле, чем взять напрокат транспортное средство на один день.",
  },
  {
    image: img6,
    title: "Все авто компании",
    text:
      "Предлагаем снять на прокат любую машину из представленных в каталоге. К вашим услугам аренда автомобилей и микроавтобусов различных ценовых категорий на сутки и длительный срок без водителя и с сопровождением.",
  },
];

const Park = () => (
  <section className="park">
    <Title text="Парк автомобилей" />
    <div className="container">
      <div className="park-text">
        <p>
          В нашем каталоге представлены легковые автомобили, пассажирские
          микроавтобусы вместительностью до 9 человек, минивэны и внедорожники,
          которые можно взять в аренду посуточно без водителя или арендовать
          машину на длительное время с водителем.
        </p>
      </div>
      <div className="park__items">
        {parkitem.map((parkitem, index) => (
          <Parkitem
            image={parkitem.image}
            title={parkitem.title}
            text={parkitem.text}
            key={index}
          />
        ))}
      </div>
    </div>
  </section>
);

export default Park;
