import './Content.css'

const Content = () => (
<section className="content">
<div className="slider">
  <h1 className="slider-text">Сервис Долгосрочной Аренды Автомобилей</h1>
</div>
</section>
);

export default Content;